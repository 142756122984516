<template>
  <section v-if="exibir == 0">
    <h2 class="titulo-links-escolha">EDITAR PERÍODO</h2>
    <pm-Card>
      <template v-slot:content>
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-12">
            <label>Nome do Período:</label>
            <pm-InputText  :disabled="perguntasCreche == true"
                v-model="serie.nome"  />
          </div>
          <div class="field col-12 md:col-12" :disabled="perguntasCreche == true">
            <label v-if="listaPerguntas.length || perguntasCreche == true">Selecione o Segmento(*para alterar o segmento retire as perguntas da creche)</label>
            <label v-else>Selecione o Segmento:</label>
            <select class="p-inputtext p-component"
              style="appearance: revert !important;width: 100% !important;"
              :disabled="listaPerguntas.length || perguntasCreche == true"
              @change="acao()"
              v-model="segmento"
               >
                <option v-for="n in segmentosALL" :key="n" :value="n"  >{{n.nome}}</option>
            </select>
          </div>
          <div class="field col-12 md:col-12">
             <fieldset :disabled="perguntasCreche == true">
                <h6 v-if="segmento">Selecione o tipo:</h6>

                <va-radio
                  option="option1"
                  v-model="radioSelectedOption"
                  @change="askToDeleteQuestions()"
                  label="Dois Períodos, com recuperação paralela no 1° e no 2°, possui Recuperação Final na segunda tabela."
                  v-if="segmento.tipo == 3"
                />

                <va-radio
                  option="option2"
                  v-model="radioSelectedOption"
                  @change="askToDeleteQuestions()"
                  label="Dois Períodos, com recuperação paralela no 1° e no 2°, possui Recuperação Final na última tabela."
                  v-if="segmento.tipo == 3"
                />

                <va-radio
                  option="option1"
                  @change="askToDeleteQuestions()"
                  v-model="radioSelectedOption"
                  label="Normal"
                  v-if="segmento.tipo == 1 || segmento.tipo == 2"
                />

                <va-radio
                  option="option3"
                  @change="askToDeleteQuestions()"
                  v-model="radioSelectedOption"
                  label="Formulário de perguntas e respostas(apenas para educação infantil)"
                  v-if="segmento.tipo == 0"
                />
              </fieldset>
          </div>
        </div>
        <div class="grid grid-nogutter justify-content-between">
          <pm-Button label="Voltar"  class="p-button-danger" icon="pi pi-angle-left" iconPos="left" @click="voltar()" />

          <pm-Button label="Editar"
             @click="editar"   class="p-button-success"
             :disabled="perguntasCreche == true"  v-if="serie.nome  != '' && segmento != ''"
             icon="pi pi-check" iconPos="right"
           />
        </div>
      </template>
    </pm-Card>

  </section >
  <section v-if="(exibir == 1 && segmento.tipo == 0 && radioSelectedOption == 'option3') || perguntasCreche">
    <h2 class="titulo-links-escolha" v-if="editarPerguntaHabilitado == 0"> ADICIONAR PERGUNTAS</h2>
    <h2 class="titulo-links-escolha" v-if="editarPerguntaHabilitado == 1"> EDITAR PERGUNTAS</h2>
    <pm-Card>
      <template v-slot:content>
        <div class="p-fluid formgrid grid">

            <div class="field col-12 md:col-12">
              <div class="form-group">
              <label for="comment" style="padding-top:15px">Digite a pergunta:</label>
              <br><br>
              <textarea class="form-control" rows="4" v-model="crechePerguntasInfo.pergunta" :disabled="foiEditado == 0"></textarea>
            </div>
            <br>

            <div class="field col-12 md:col-12">
            <h6>A pergunta é de multiplas escolhas?</h6>
              <fieldset>
                <va-radio
                  :disabled="foiEditado == 0"
                  option="option1"
                  v-model="radioSelectedOption1"
                  label="Sim"
                />
                <va-radio
                  :disabled="foiEditado == 0"
                  option="option2"
                  v-model="radioSelectedOption1"
                  label="Não"
                />
              </fieldset>
            </div>

            <div class="field col-12 md:col-2" >


              <pm-Button label="Adicionar nova pergunta"
                :disabled="crechePerguntasInfo.pergunta == ''"
                @click="enviarPergunta"
                v-if="serie.nome != null && serie.nome != '' &&  serie.segmento != '' && editarPerguntaHabilitado == 0"
                />

              <pm-Button label="Editar  pergunta"
                :disabled="crechePerguntasInfo.pergunta == ''"
                @click="editarPergunta"
                v-if="serie.nome != null && serie.nome != '' &&  serie.segmento != '' && editarPerguntaHabilitado == 1"
                />
            </div>

            <div class="field col-12 md:col-2" style="margin-top:25px">
               <pm-Button label="Voltar"  class="p-button-danger" icon="pi pi-angle-left" iconPos="left" @click="voltar()" />
            </div>
          </div>
         </div>
      </template>
      <br>
    </pm-Card>
    <br>
    <div class="pm-card">
      <pm-DataTable :value="listaPerguntas"   dataKey="id" :rowHover="true" filterDisplay="menu"
         responsiveLayout="scroll">

          <pm-Column field="pergunta" sortable header="PERGUNTA" style="font-size: 14px;vertical-align:middle !important"></pm-Column>
          <pm-Column field="multipla_escolha"  header="A PERGUNTA É DE MULTIPLAS ESCOLHAS?" style="font-size: 14px;vertical-align:middle !important"></pm-Column>

          <pm-Column field="all" headerStyle="width: 4rem; text-align: center"
             bodyStyle="text-align: center; overflow: visible;width: 357px;vertical-align:middle !important">
            <template #body="{data}">

              <pm-Button icon="pi pi-user-edit" style="margin-right: 10px;"
                label="Editar" title="Editar"
                class="p-button-sm btn-color" @click="editarPerguntaReceberDados(data.all)"
              />

              <pm-Button label="Apagar"
                icon="pi pi-times-circle" class="p-button-danger p-button-sm btn-color" title="Apagar"
                @click="deletarPergunta(data.all.id)"   />

            </template>
          </pm-Column>

      </pm-DataTable>
    </div>
  </section>
</template>

<script>

import { SegmentoEscolar } from "@/class/segmentoEscolar.js";
import store_token_info from "@/store/store_token_info.js";
import { SeriesEscolar } from "@/class/serie.js";
import { Pergunta } from "@/class/pergunta.js";

export default {
  props: {
    id:{
    },
    exibir:{
      default:0,
    }
  },
  components: {

  },
  name: 'SerieEditar',
  data () {
    const columns = [
      { key: "pergunta", label: "Pergunta", sortable: true },
      { key: "multipla_escolha", label: "A PERGUNTA É DE MULTIPLAS ESCOLHAS?", sortable: true },
      { key: "all", label: "Ação" },
    ];
    return {
      foiEditado:0,
      perguntasCreche:0,
      filter: '',
      columns,
      perPage: 10,
      currentPage: 1,
      editarPerguntaHabilitado:0,
      comecouComFormulario:0,
      radioSelectedOption:"option1",
      radioSelectedOption1:"option1",
      anoLetivo:0,
      serie: {
        nome: null,
        segmento: '',
        segmento_id:null,
        tipo:null,
        user_id: store_token_info.usuario_logado,
      },
      listaPerguntas: [],
      crechePerguntasInfo: {
        serie_id:'',
        pergunta:'',
        multipla_escolha:'',
      },
      segmento:[],
      segmentosALL:[],
    }
  },
  methods: {
    voltar(){this.$router.push({name: 'coordenacao-serie'});},
    acao() {
      if (this.askToDeleteQuestions()) {
        if (this.segmento.id == 5) {
          this.radioSelectedOption = "option3";
        }
      }
    },
    askToDeleteQuestions() {
      if (this.comecouComFormulario && this.listaPerguntas.length) {
        this.radioSelectedOption = "option3";
        this.$vaToast.init({
          message: "É necessário excluir todas as perguntas antes de mudar de tipo!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          color: 'warning',
          duration: 3500,
          fullWidth: false,
        })
        return false;
        }
        return true;
    },

    async editarOption(id){
      try {
        let data = await SeriesEscolar.obtemUm(id);
        this.serie.id = data.data.id;
        this.serie.nome = data.data.nome;
        if (data.data.tipo == 1)
          this.radioSelectedOption = "option1";
        if (data.data.tipo == 2)
          this.radioSelectedOption = "option2";
        if (data.data.tipo == 3)
          this.radioSelectedOption = "option3";
        this.serie.tipo = data.data.tipo;
        this.foiEditado = (this.radioSelectedOption == "option3" ? 1 : 0);
        this.segmento = this.getSegmento(data.data.segmento_id);
        this.serie.segmento = this.segmento.id;
        if (data.data.tipo == 3) {
          this.crechePerguntasInfo.serie_id = data.data.id;
          this.comecouComFormulario = 1;
          this.buscarPerguntas();
        }
      } catch (e) {
      }
    },
    async listaSegmento(){
       const data = await SegmentoEscolar.obtemTodos();
       for (const el of data.data) {
          let n = {
            id: el.id,
            nome: el.nome,
            tipo: el.tipo,
            status: el.status,
            created_at: el.created_at,
            updated_at: el.updated_at,
          };
          this.segmentosALL.push(n);
       }
       //this.segmentosALL = data.data;
    },
    getSegmento(segmento_id) {
      for (const el of this.segmentosALL) {
        if (el.id == segmento_id) {
          return el;
        }
      }
    },  
    async deletarPergunta(id) {
      if (window.confirm("Você realmente deseja deletar a pergunta selecionada?")) {
        try {
          let data = await Pergunta.remover(id);
          this.$vaToast.init({
            message: "Pergunta deletada com sucesso!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            color: 'success',
            duration: 2500,
            fullWidth: false,
          });
          this.buscarPerguntas();
        }
        catch(e) {
          this.$vaToast.init({
            message: "Problema ao deletar a pergunta!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            color: 'danger',
            duration: 2500,
            fullWidth: false,
          });
        }
      }
    },

    async enviarPergunta() {
      try {
        this.crechePerguntasInfo.multipla_escolha = (this.radioSelectedOption1 == "option1" ? 1 : 0);
        this.crechePerguntasInfo.serie_id = this.serie.id;
        let data = await Pergunta.cadastrar(this.crechePerguntasInfo);
        this.$vaToast.init({
            message: "Pergunta adicionada com sucesso!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            color: 'success',
            duration: 2500,
            fullWidth: false,
        });
        this.crechePerguntasInfo.pergunta = '';
        this.radioSelectedOption1 = "option1"
        this.buscarPerguntas();

      }
      catch(e) {
        this.$vaToast.init({
            message: "Problema ao editar a nota!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            color: 'danger',
            duration: 2500,
            fullWidth: false,
        });
      }
    },

    async editar(){
      try {
         let tipo = 1;
         if (this.radioSelectedOption == "option2")
          tipo = 2;
         if (this.radioSelectedOption == "option3")
          tipo = 3;
          let novo ={
            id:this.serie.id,
            nome:this.serie.nome,
            segmento_id:this.segmento.id,
            tipo: tipo,
          };
          const data = await SeriesEscolar.editar(novo);
          if (this.radioSelectedOption != "option3") {
            this.$vaToast.init({
              message: "Editado com sucesso!",
              iconClass: 'fa-star-o',
              position: 'top-right',
              duration: 2500,
              fullWidth: false,
            })
            this.$router.push({name: 'coordenacao-serie'});
          }
          else {
            this.perguntasCreche = true;
            this.foiEditado = 1;
          }
      }
      catch(e) {
        if(e.response.data.validacao){
          let a = [];
          a = e.response.data.validacao;

          Object.entries(a).forEach(([key, value]) => {
            setTimeout(() => {
              this.$vaToast.init({
                message: value[0],
                iconClass: 'fa-star-o',
                position: 'top-right',
                duration: 3500,
                fullWidth: false,
                color: 'danger'
              });
            }, 500);

          });
        }else{
          let a = [];
          a = e.response.data.erro;

            setTimeout(() => {
              this.$vaToast.init({
                message: a[0],
                iconClass: 'fa-star-o',
                position: 'top-right',
                duration: 3500,
                fullWidth: false,
                color: 'danger'
              });
            }, 500);
        }
      }
    },
    async editarPerguntaReceberDados(all) {
      this.crechePerguntasInfo.id = all.id;
      this.crechePerguntasInfo.pergunta = all.pergunta
      this.crechePerguntasInfo.multipla_escolha = all.multipla_escolha;
      this.radioSelectedOption1 = (all.multipla_escolha == 1 ? "option1" : "option2");
      this.editarPerguntaHabilitado = !this.editarPerguntaHabilitado;

    },
    async editarPergunta() {
       try {
        this.crechePerguntasInfo.multipla_escolha = (this.radioSelectedOption1 == "option1" ? 1 : 0);
        let data = await Pergunta.alterar(this.crechePerguntasInfo);
        this.$vaToast.init({
            message: "Pergunta editada com sucesso!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            color: 'success',
            duration: 2500,
            fullWidth: false,
        });
        this.crechePerguntasInfo.pergunta = '';
        this.radioSelectedOption1 = "option1";
        this.editarPerguntaHabilitado = !this.editarPerguntaHabilitado;
        this.buscarPerguntas();

      }
      catch(e) {
        this.$vaToast.init({
            message: "Problema ao editar a pergunta",
            iconClass: 'fa-star-o',
            position: 'top-right',
            color: 'danger',
            duration: 2500,
            fullWidth: false,
        });
      }
    },
    async buscarPerguntas() {
      let data = await Pergunta.obtemTodos(this.crechePerguntasInfo.serie_id, this.anoLetivo);
      this.listaPerguntas = [];
      for (const el of data.data) {
        let novo = {
          id: el.id,
          pergunta: el.pergunta,
          multipla_escolha: (el.multipla_escolha == 1 ? "SIM" : "NÃO"),
          all: {
            id: el.id,
            pergunta: el.pergunta,
            multipla_escolha: el.multipla_escolha,
          }
        }
        this.listaPerguntas.push(novo);
      }
      this.listaPerguntas.reverse();
    },
  },
  mounted() {
    if (this.id == null) {
      this.$router.push({name: 'coordenacao-serie'});
    }
    else {
      this.anoLetivo = sessionStorage.getItem("anoSelecionado");
      this.listaSegmento();
      this.editarOption(this.id);
    }
  }
}
</script>

<style>
  .row.row-inside {
    max-width: none;
  }

  .form-control:focus {
    background-color: #f6f7f6;
    border-color: #673ab7;
    box-shadow: none;
  }
  .form-control {
    background-color: #f6f7f6;
    border: 1px solid #f6f6f6;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
     margin: 0;
  }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }


  .va-input-wrapper, fieldset {
    margin-bottom: 0.5rem;
  }
</style>
